export default [
    {
        path: "/",
        name: "default",
        component: () => import("../views/registration/registration"),
        meta: {
            title: "Anmelden",
        },
    }
];
